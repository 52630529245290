<script>
export default {
  name: "AppTag",

  props: {
    close: {
      type: Boolean,
      default: false,
      required: false
    },

    color: {
      type: String,
      default: "",
      required: false
    },

    text_color: {
      type: String,
      default: "",
      required: false
    },

    label: {
      type: [String, Object],
      default: "",
      required: true
    }
  }
}
</script>

<template>
  <div class="app-tag" :style="{ color: text_color, background: color }" v-if="label">
    <span class="app-tag__text">{{label}}</span>
    <div class="app-tag__close" v-if="close" @click="$emit('close')">
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 3183589">
          <path id="Cross" fill-rule="evenodd" clip-rule="evenodd" d="M1.29058 0.244328C0.997691 -0.0485651 0.522817 -0.0485651 0.229924 0.244328C-0.0629694 0.537221 -0.0629694 1.01209 0.229924 1.30499L3.94757 5.02264L0.230277 8.73994C-0.0626163 9.03283 -0.0626159 9.5077 0.230277 9.8006C0.523171 10.0935 0.998044 10.0935 1.29094 9.8006L5.00823 6.0833L8.72553 9.8006C9.01843 10.0935 9.4933 10.0935 9.78619 9.8006C10.0791 9.50771 10.0791 9.03284 9.78619 8.73994L6.06889 5.02264L9.78655 1.30499C10.0794 1.0121 10.0794 0.537221 9.78655 0.244328C9.49365 -0.0485651 9.01878 -0.0485651 8.72589 0.244328L5.00823 3.96198L1.29058 0.244328Z" fill="#305CA8"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-tag {
  border-radius: 8px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
  display: inline-flex;

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
  }

  &__close {
    margin-left: 8px;
    padding-top: 2px;
    cursor: pointer;
    display: inline-block;
    flex-shrink: 0;
    min-width: 10px !important;
  }
}
</style>